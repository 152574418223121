/* Base Tailwind imports */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Custom base styles */
@layer base {
  html {
    @apply antialiased;
  }
  
  body {
    @apply m-0 font-sans bg-gray-50;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

/* Custom components */
@layer components {
  .btn {
    @apply inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm;
  }
  
  .btn-primary {
    @apply text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500;
  }
  
  .btn-secondary {
    @apply text-blue-700 bg-blue-100 hover:bg-blue-200;
  }
  
  .input {
    @apply block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm;
  }

  .form-label {
    @apply block text-sm font-medium text-gray-700 mb-1;
  }

  .card {
    @apply bg-white shadow rounded-lg p-6;
  }

  .page-container {
    @apply min-h-screen bg-gray-50 flex flex-col;
  }

  .content-container {
    @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8;
  }
}

/* Custom utilities */
@layer utilities {
  .text-shadow {
    text-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .transition-fast {
    @apply transition-all duration-150 ease-in-out;
  }
  
  .transition-medium {
    @apply transition-all duration-300 ease-in-out;
  }
}
