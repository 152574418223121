@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Base styles */
@layer base {
  body {
    @apply m-0 font-sans antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

/* Component styles */
@layer components {
  .btn {
    @apply px-4 py-2 rounded-md font-medium transition-colors;
  }
  
  .btn-primary {
    @apply bg-blue-600 text-white hover:bg-blue-700;
  }
  
  .input {
    @apply w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500;
  }

  /* Form styles */
  .form-input {
    @apply appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm;
    @apply placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500;
    @apply sm:text-sm;
  }

  .form-label {
    @apply block text-sm font-medium text-gray-700;
  }

  /* Layout styles */
  .page-container {
    @apply min-h-screen bg-gray-50 flex flex-col;
  }

  .content-container {
    @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8;
  }

  /* Card styles */
  .card {
    @apply bg-white shadow rounded-lg p-6;
  }
}

/* Custom utilities */
@layer utilities {
  .text-shadow {
    text-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
}
